<template>
  <v-card class="px-4 py-2">
    <v-row>
      <v-col>
        <v-list> Chat with: {{ booking.booked_user }} </v-list>
        <v-list> Subject: {{ booking.subject_name }} </v-list>
        <v-list> Grade: {{ booking.subject_grade }} </v-list>
      </v-col>
      <v-col
        v-for="message in messageTexts"
        :key="message.id"
        cols="12"
        :class="
          message.sender === $store.getters['user/getUsername']
            ? 'd-flex justify-end'
            : 'd-flex justify-start'
        "
      >
        <v-chip-group v-if="message.type === 'subject_offer'">
          <v-chip
            v-for="item in JSON.parse(message.text)"
            :key="item.id"
            label
            color="primary"
            text-color="white"
          >
            <!-- Would you like to join room {{ item }}? -->
            Would you like to join room {{ roomNameMap[item] }} ?

          </v-chip>
        </v-chip-group>
        

        <v-chip
          v-else-if="message.type === 'subject_accept'"
          label
          color="purple lighten-2"
          text-color="white"
        >
          {{ message.sender }} has joined the room.
        </v-chip>

        <v-chip
          v-else-if="message.type === 'subject_reject'"
          label
          color="red lighten-2"
          text-color="white"
        >
          {{ message.sender }} has rejected the room.
        </v-chip>
        <v-chip v-else>
          {{ message.text }}
        </v-chip>
      </v-col>
    </v-row>
    <v-text-field v-model="message">
      <template #append>
        <v-dialog v-model="dialogShowSubjects" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-google-classroom</v-icon>
            </v-btn>
          </template>

          <v-card height="400" class="pa-4">
            <v-card-title class="text-h5 grey lighten-2">
              Offer Rooms
            </v-card-title>
            <!-- <room-auto-complete
              v-model="selectedRoomsToOffer"
              :returnObject="true"
              :multiple="true"
            ></room-auto-complete> -->
            
            <v-autocomplete
              v-model="selectedRoomsToOffer"
              :items="allRoom"
              item-text="name" 
              item-value="id"
              multiple
            ></v-autocomplete>
          </v-card>
        </v-dialog>
        <!-- <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="sendAcceptanceOffer">
              <v-icon>mdi-help-rhombus</v-icon>
            </v-btn>
          </template>
          <span>send acceptance offer</span>
        </v-tooltip> -->
      </template>
      <template #append-outer>
        <v-btn icon @click="sendMessage">
          <v-icon>mdi-send</v-icon>
        </v-btn>
      </template>
    </v-text-field>
  </v-card>
</template>

<script>
import RoomAutoComplete from "../RoomAutoComplete.vue";
import RoomSubjectAutoComplete from "../RoomSubjectAutoComplete.vue";
export default {
  components: { RoomAutoComplete, RoomSubjectAutoComplete },
  props: {
    booking: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    selectedRoomsToOffer: [],
    
    messages: [],
    wsConnection: null,
    dialogShowSubjects: false,
    message: "",
    allRoom:[],
  }),
  created() {
    this.loadRooms();
    this.initializeWS();
    this.wsConnection.onopen = () => {};
    this.wsConnection.onmessage = (event) => {
      this.messages.push({ event: JSON.parse(event.data) });
    };
  },
  async mounted() {
    await this.loadMessages();
  },
  watch: {
    selectedRoomsToOffer(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.message = newVal.map((item) => item.id).join(", ");
      }
    },
  },
  // watch: {
  //   selectedRoomsToOffer(newVal, oldVal) {
  //     if (Array.isArray(newVal)) {
  //       this.message = newVal.map((item) => item.name).join(", ");
  //     }
  //   },
  // },

  computed: {
    messageTexts() {
      return this.messages.map((message) => ({
        text: message.event.message,
        type: message.event.type,
        sender: message.event.sender,
      }));
    },
    roomNameMap() {
    const roomNameMap = {};
    this.allRoom.forEach(room => {
      roomNameMap[room.id] = room.name;
    });
    return roomNameMap;
  }
  },
  destroyed() {
    this.wsConnection.close();
    this.wsConnection = null;
    this.messages = [];
    this.message = "";
  },
  methods: {
    loadRooms(){
      this.$api.get("classrooms").then((r)=>{this.allRoom=r.data.results})
    },
    loadMessages() {
      return this.$api
        .get("/chat/messages/", {
          params: {
            booking: this.booking.id,
          },
        })
        .then((response) => {
          this.messages = response.data.results;
        });
    },
    initializeWS() {
      this.wsConnection = new WebSocket(
        `${
          process.env.NODE_ENV === "production"
            ? process.env.VUE_APP_CHAT_URL
            : process.env.VUE_APP_CHAT_URL_DEV
        }/ws/chat/listing/${this.booking.id}/?access_token=${
          this.$store.getters["user/getAccessToken"]
        }`
      );
    },
    sendAcceptanceOffer() {
      this.wsConnection.send(
        JSON.stringify({
          message: "Will you join?",
          event: "acceptance_offer",
        })
      );
      this.dialogShowSubjects = false;
      this.selectedRoomsToOffer = [];
    },
    sendMessage() {
      this.wsConnection.send(
        JSON.stringify({
          message:
            this.selectedRoomsToOffer.length > 0
              ? JSON.stringify(this.selectedRoomsToOffer)
              : this.message,
          ...(this.selectedRoomsToOffer.length > 0 && {
            has_responded: false,
          }),
          event:
            this.selectedRoomsToOffer.length > 0
              ? "subject_offer"
              : "chat_message",
        })
      );

      // this.messages.push({
      //   event: {
      //     message: this.message,
      //     type: "chat_message",
      //   },
      // });
      this.message = "";
      this.selectedRoomsToOffer = [];
    },
  },
};
</script>

<style>
</style>