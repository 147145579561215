<template>
  <v-container>
    <v-dialog v-model="chatDialog" width="500">
      <listing-chat
        v-if="selectedBooking"
        :booking="selectedBooking"
        :key="selectedBooking.id"
      ></listing-chat>
    </v-dialog>
    <v-row>
      <v-col v-for="booking in bookings" :key="booking.id">
        <v-card
          :key="booking.id"
          @click="
            () => {
              selectedBooking = booking;
              chatDialog = true;
            }
          "
        >
          <v-card-title>Chat with: {{ booking.booked_user }}</v-card-title>
          <v-card-title>Tution Model: {{ booking.tuition_model }}</v-card-title>
          <v-card-text>Booking Time: {{ moment(booking.created_at ).format("Do MMM YY") }}  </v-card-text>

          <v-card-text>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title> Subject:</v-list-item-title>
                  <v-list-item-subtitle>{{
                    booking.subject_name
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title> Grade:</v-list-item-title>
                  <v-list-item-subtitle>{{
                    booking.subject_grade
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>For availability :</v-list-item-title>
                  <v-list-item-subtitle>{{
                    booking.for_availability
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Offered price</v-list-item-title>
                  <v-list-item-subtitle>{{
                    booking.offered_price
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title> Accepted Status:</v-list-item-title>
                  <v-list-item-subtitle>
                    <!-- {{
                    booking.is_accepted
                  }} -->
                    {{ displayIsActive(booking.is_accepted) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Is Active:</v-list-item-title>
                  <v-list-item-subtitle>{{
                    booking.is_active ? "Yes" : "No"
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary">Chat</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ListingChat from "../../../components/teacher/ListingChat.vue";
export default {
  components: { ListingChat },
  data: () => ({
    bookings: [],
    selectedBooking: null,
    chatDialog: false,
  }),
  mounted() {
    this.loadConversations();
  },
  methods: {
    loadConversations() {
      this.$api.get("/listing/bookings/teacher-bookings").then((response) => {
        this.bookings = response.data.results;
      });
    },

    displayIsActive(isActive) {
      if (isActive === true) {
        return "Yes";
      } else if (isActive === false) {
        return "No";
      } else {
        return "Unknown";
      }
    },
  },
};
</script>

<style>
</style>